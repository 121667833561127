// type ActionTypes = | 'clear' | 'create-option' | 'deselect-option' | 'pop-value' | 'remove-value' | 'select-option' | 'set-value'
// select-option: Selecting an option from the list
// deselect-option: (Multiple) Deselecting an option from the list
// remove-value: (Multiple) Removing a selected option with the remove button
// pop-value: Removing options using backspace
// set-value: Calling setValue from a component without an action
// clear: Removing all selected options with the clear button
// create-option: (Creatable) Creating a new option
export const DROPDOWN_ACTION_TYPES = {
  CLEAR: "clear",
  "CREATE-OPTION": "create-option",
  "INPUT-CHANGE": "input-change",
  "SELECT-OPTION": "select-option",
  "DESELECT-OPTION": "deselect-option",
  "POP-VALUE": "pop-value",
  "REMOVE-VALUE": "remove-value",
  "SET-VALUE": "set-value"
};

export const REACT_SELECT_ACTION_TYPE = {
  "INPUT-CHANGE": "input-change"
};
