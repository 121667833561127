import React from "react";
import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { RiErrorWarningFill } from "react-icons/ri";

const InputWrapper = ({
  children,
  label,
  hasLabel = true,
  isInvalid,
  errorMessage = "Value is invalid",
  wrapperRightElement,
  ...props
}) => {
  const hasRightElement = Boolean(wrapperRightElement);

  return (
    <Box w="100%" {...props}>
      {hasLabel && (
        <Flex w="100%" align="center" justify="space-between">
          <Text
            w={hasRightElement ? "50%" : "100%"}
            color={isInvalid ? "mandala.red.500" : "mandala.black.900"}
          >
            {label}
          </Text>
          {hasRightElement && wrapperRightElement}
        </Flex>
      )}
      {children}
      {isInvalid && (
        <Flex align="center" color="mandala.red.500" mt=".5em">
          <Icon as={RiErrorWarningFill} />
          <Text variant="subtitle" color="mandala.red.500" px=".5em">
            {errorMessage}
          </Text>
        </Flex>
      )}
    </Box>
  );
};

export default InputWrapper;
