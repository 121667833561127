import React from "react";
import { Input, Stack, useClipboard } from "@chakra-ui/react";
import { IconButton } from "@chakra-ui/react";
import { CgCopy } from "react-icons/cg";
import InputWrapper from "../../Fragments/InputWrapper";

const TextInput = ({
  label,
  value,
  placeholder,
  hasCopyButton = false,
  onChange = () => {},
  wrapperProps = {},
  ...props
}) => {
  const { isInvalid = false, errorMessage = "Value is invalid" } = props;
  const { hasCopied, onCopy } = useClipboard(value);
  return (
    <InputWrapper
      label={label}
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      {...wrapperProps}
    >
      <Stack w="100%" direction={"row"} spacing={"1.5"}>
        <Input
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          bg="white"
          {...props}
        />
        {hasCopyButton && (
          <IconButton
            variant={hasCopied ? "solid" : "outline"}
            onClick={onCopy}
            borderColor="transparent"
            bgColor={hasCopied ? "mandala.blue.500" : "#f4f4f4"}
            icon={<CgCopy />}
          />
        )}
      </Stack>
    </InputWrapper>
  );
};

export default TextInput;
