import React from "react";
import Select from "react-select";
import { defaultStyle } from "src/theme/components/select";
import { SearchIcon } from "../Fragments/SearchIcon";

const SearchboxSelect = ({
  value,
  options,
  placeholder = "Search...",
  maxMenuHeight = 250,
  ...props
}) => {
  // stringValue to get string of value {Object/String}
  const stringValue = value?.value ?? value;

  return (
    <Select
      isClearable={true}
      options={options}
      styles={defaultStyle}
      noOptionsMessage={() => "There's no result found"}
      placeholder={placeholder}
      value={options.find(({ value: v }) => v === stringValue) || value || null}
      components={{ Control: SearchIcon, DropdownIndicator: null }}
      maxMenuHeight={maxMenuHeight}
      {...props}
    />
  );
};

export default SearchboxSelect;
