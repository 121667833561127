import React from "react";
import { Input } from "@chakra-ui/react";
import InputWrapper from "../../Fragments/InputWrapper";

const DateTimeInput = ({ label, placeholder, wrapperProps = {}, ...props }) => {
  const { isInvalid = false, errorMessage = "Value is invalid" } = props;

  return (
    <InputWrapper
      label={label}
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      pr="2em"
      {...wrapperProps}
    >
      <Input type="datetime-local" placeholder={placeholder} {...props} />
    </InputWrapper>
  );
};

export default DateTimeInput;
