import React from "react";
import {
  NumberInput as ChakraUINumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper
} from "@chakra-ui/react";
import InputWrapper from "../../Fragments/InputWrapper";

const NumberInput = ({
  label,
  value,
  onChange = () => {},
  wrapperProps = {},
  ...props
}) => {
  const { isInvalid = false, errorMessage = "Value is invalid" } = props;

  return (
    <InputWrapper
      label={label}
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      {...wrapperProps}
    >
      <ChakraUINumberInput value={value} onChange={onChange} {...props}>
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </ChakraUINumberInput>
    </InputWrapper>
  );
};

export default NumberInput;
