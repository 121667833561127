import React from "react";
import debounce from "lodash/debounce";

/**
 * This hooks responsible for handling state (both display value and the actual state value)
 * for <SearchBoxInput /> component.
 *
 * @param {*} config
 * @returns {*}
 */
export const useSearchBox = (config = {}) => {
  const { delay = 500, value: initValue } = config;

  /**
   * On this hook primarly we need two state, the first one is to manage "controlled input"
   * in this case it was assigned to "displayValue", the second one is to manage internally
   * the debounced/delayed value for whatever purpose.
   *
   * displayValue: manage "controlled input" for input
   * searchValue: manage and handle debounced value
   */
  const [displayValue, _setDisplayValue] = React.useState(initValue);
  const [searchValue, _setSearchValue] = React.useState(initValue);

  // Handle change display value
  const setDisplayValue = value => _setDisplayValue(value);

  // Handle trigger delay for "searchValue" state
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _handleSearch = React.useCallback(
    debounce(value => _setSearchValue(value), delay),
    [debounce, _setSearchValue]
  );

  // Supplies onClear props on <SearchBoxInput />
  const handleClearSearch = () => {
    _setDisplayValue("");
    _setSearchValue("");
  };

  // Side effect to delay between the displayValue and searchValue
  React.useEffect(() => {
    _handleSearch(displayValue);
  }, [_handleSearch, displayValue]);

  React.useEffect(() => {
    _setDisplayValue(initValue);
  }, [_setDisplayValue, initValue]);

  return {
    displayValue,
    setDisplayValue,
    searchValue,
    handleClearSearch
  };
};
