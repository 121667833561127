import React from "react";
import InputWrapper from "../../Fragments/InputWrapper";
import { Box, Button } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/hooks";
import CodeEditorModal from "./Fragments/CodeEditorModal";
import CodeEditor from "./Fragments/CodeEditor";
import { useRectMeasure } from "src/hooks/useRectMeasure";
import { FileInput } from "src/components/MandalaUI/Inputs";

export const CODE_EDITOR_LANGUAGE_MODE = {
  SQL: "sql",
  PYTHON: "python"
};

const CodeInput = ({
  label,
  value,
  onFinish,
  isDisabled,
  onAfterOpen,
  withUploadFile = false,
  modalTitle,
  isCodeEditorDisabled,

  codeEditorMode = CODE_EDITOR_LANGUAGE_MODE.SQL,
  ...props
}) => {
  const { isInvalid = false, errorMessage = "Value is invalid" } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { ref, rect } = useRectMeasure();
  const hasOnAfterOpen = Boolean(onAfterOpen);
  const shouldMinimizeFileInput = Boolean(value);

  const updateFileContent = file => {
    if (file) {
      const fr = new FileReader();

      fr.readAsText(file);
      fr.onload = e => {
        const fileContent = e.target.result;
        // in most cases, onFinish refers to setState elsewhere so this is safe enough
        onFinish(fileContent);
      };
    }
  };

  return (
    <React.Fragment>
      {withUploadFile && (
        <FileInput
          onChange={updateFileContent}
          wrapperProps={{
            h: shouldMinimizeFileInput ? 0 : "auto"
          }}
          isButtonUploadMinimized={shouldMinimizeFileInput}
        />
      )}
      <CodeEditorModal
        isOpen={isOpen}
        onClose={onClose}
        onFinish={onFinish}
        value={value}
        modalTitle={modalTitle}
        isCodeEditorDisabled={isCodeEditorDisabled}
        mode={codeEditorMode}
      />
      <InputWrapper
        label={label}
        isInvalid={isInvalid}
        errorMessage={errorMessage}
        {...props}
      >
        <Box
          position="relative"
          h="15em"
          border="solid 1px"
          borderColor="mandala.black.300"
          borderRadius=".25em"
          bg={isDisabled ? "mandala.black.100" : "white"}
          ref={ref}
        >
          <Button
            data-testid="open-code-editor-button"
            isDisabled={isDisabled}
            onClick={() => {
              onOpen();
              if (hasOnAfterOpen) {
                onAfterOpen();
              }
            }}
            pos="absolute"
            opacity={0}
            w={rect.width}
            h={rect.height}
            // since ace scroller have 6 zIndex, we need to make
            // this button a bit higher
            zIndex={7}
            _disabled={{ display: "none" }}
            _hover={{ opacity: isDisabled ? 0 : 0.8 }}
          >
            Open Code Editor
          </Button>
          <Box padding=".5em" h="15em">
            <CodeEditor
              value={value}
              name="code-editor-component"
              h="15em"
              style={{
                borderRadius: ".25em",
                pointerEvent: "none"
              }}
              readOnly={true}
              highlightActiveLine={false}
              showGutter={false}
              setOptions={{
                useWorker: false,
                showLineNumbers: true,
                tabSize: 2
              }}
              mode={codeEditorMode}
            />
          </Box>
        </Box>
      </InputWrapper>
    </React.Fragment>
  );
};

export default CodeInput;
