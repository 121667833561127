import React from "react";
import { Text, Tooltip } from "@chakra-ui/react";

export const OverflownText = ({
  tooltipProps,
  label,
  textComponent = Text,
  children,
  ...props
}) => {
  const ref = React.useRef(null);
  const [isOverflown, setIsOverflown] = React.useState(false);

  React.useEffect(() => {
    const textElement = ref.current;

    const handleIntersection = entries => {
      entries.forEach(entry => {
        setIsOverflown(
          entry.isIntersecting &&
            textElement.clientWidth < textElement.scrollWidth
        );
      });
    };

    // only calculate isOverflown state if the children is visible
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5
    });

    // start observing if textElement has been mounted
    if (textElement) {
      observer.observe(textElement);
    }

    return () => {
      if (textElement) {
        observer.unobserve(textElement);
      }
    };
  }, []);

  const TextComponent = textComponent;

  return (
    <Tooltip
      hasArrow
      label={label || children}
      isDisabled={!isOverflown}
      {...tooltipProps}
    >
      <TextComponent
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        ref={ref}
        {...props}
      >
        {children}
      </TextComponent>
    </Tooltip>
  );
};
