import React from "react";
import {
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  useClipboard,
  Stack
} from "@chakra-ui/react";
import InputWrapper from "../../Fragments/InputWrapper";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { CgCopy } from "react-icons/cg";

const PasswordInput = ({
  label,
  value,
  placeholder,
  hasCopyButton = false,
  onChange = () => {},
  ...props
}) => {
  const {
    isInvalid = false,
    errorMessage = "Value is invalid",
    isDisabled = false
  } = props;
  const { hasCopied, onCopy } = useClipboard(value);
  const [show, setShow] = React.useState(false);
  const handleShowPassword = () => setShow(!show);

  return (
    <InputWrapper
      label={label}
      isInvalid={isInvalid}
      errorMessage={errorMessage}
    >
      <Stack w="100%" direction={"row"} spacing={"1.5"}>
        <InputGroup w="100%" bg="white">
          <Input
            type={show ? "text" : "password"}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            {...props}
          />
          <InputRightElement w="10%" pr="1em">
            <IconButton
              onClick={handleShowPassword}
              size="sm"
              rounded="full"
              isDisabled={isDisabled}
              icon={show ? <FaEye /> : <FaEyeSlash />}
              variant="transparent"
              colorScheme="black"
              _focus={{ boxShadow: "none" }}
            />
          </InputRightElement>
        </InputGroup>
        {hasCopyButton && (
          <IconButton
            variant={hasCopied ? "solid" : "outline"}
            onClick={onCopy}
            borderColor="transparent"
            bgColor={hasCopied ? "mandala.blue.500" : "#f4f4f4"}
            icon={<CgCopy />}
          />
        )}
      </Stack>
    </InputWrapper>
  );
};

export default PasswordInput;
