import React from "react";
import { Box, Flex, Icon, Switch, Text } from "@chakra-ui/react";
import { RiErrorWarningFill } from "react-icons/ri";

const SwitchInput = ({
  label,
  subtitle,

  onChange = () => {},
  isChecked = false,

  errorMessage = "Something is wrong",

  labelProps = {},

  wrapperProps = {},
  isShowLabelOnOff = false,

  ...props
}) => {
  const { isInvalid } = props;
  const hasLabel = Boolean(label);
  const hasSubtitle = Boolean(subtitle);

  return (
    <Box w="100%" {...wrapperProps}>
      <Flex w="100%" align="center" gap="0.5em">
        <Switch
          onChange={onChange}
          isChecked={isChecked}
          sx={
            isShowLabelOnOff
              ? {
                  ".chakra-switch__track::after": {
                    content: isChecked ? '"ON"' : '"OFF"',
                    color: isChecked ? "white" : "mandala.black.400",
                    display: "block",
                    position: "absolute",
                    transform: "translate(-50%,-50%)",
                    top: "14px", // calc half of height
                    left: isChecked ? "28%" : "68%",
                    fontWeight: "bold",
                    fontSize: "sm"
                  },
                  ".chakra-switch__track": {
                    paddingLeft: isChecked ? "1em" : ".15em",
                    paddingRight: isChecked ? ".15em" : "1em"
                  }
                }
              : {}
          }
          {...props}
        />

        {hasLabel && <Text {...labelProps}>{label}</Text>}
      </Flex>
      {hasSubtitle && (
        <Text variant="subtitle" mt=".5em" color="mandala.black.500">
          {subtitle}
        </Text>
      )}
      {isInvalid && (
        <Flex align="center" color="mandala.red.500" mt=".5em">
          <Icon as={RiErrorWarningFill} />
          <Text variant="subtitle" color="mandala.red.500" px=".5em">
            {errorMessage}
          </Text>
        </Flex>
      )}
    </Box>
  );
};

export default SwitchInput;
