import React from "react";
import { BsSearch } from "react-icons/bs";
import { Icon } from "@chakra-ui/react";
import { components } from "react-select";

export const SearchIcon = ({ children, ...props }) => (
  <components.Control {...props}>
    <Icon as={BsSearch} color="mandala.black.500" px=".5em" w="15px" h="15px" />
    {children}
  </components.Control>
);
