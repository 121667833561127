import React from "react";
import {
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  IconButton,
  Button
} from "@chakra-ui/react";
import { useSearchBox } from "./useSearchbox";
import { BsSearch } from "react-icons/bs";
import { MdCancel } from "react-icons/md";

const SearchInput = React.forwardRef(
  (
    {
      placeholder = "Search...",
      onSearch = () => {},
      isDebounced = false,
      isClearable = true,
      isLoading = false,
      config = { delay: 500 },
      searchIconWidth = "10%",
      value = "",
      hasTriggerResetSearchValue = false,
      ...props
    },
    ref
  ) => {
    const { displayValue, setDisplayValue, searchValue, handleClearSearch } =
      useSearchBox({
        delay: !isDebounced ? 0 : config.delay,
        value,
        ...config
      });

    // Trigger clearSearch on higher level
    React.useEffect(() => {
      if (!hasTriggerResetSearchValue) {
        return;
      }

      handleClearSearch();
    }, [hasTriggerResetSearchValue, handleClearSearch]);

    // Trigger search on higher level
    React.useEffect(() => {
      onSearch(searchValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    return (
      <InputGroup w="100%" display="flex">
        <Input
          ref={ref}
          type="text"
          outline="0px"
          value={displayValue}
          onChange={e => setDisplayValue(e.target.value)}
          placeholder={placeholder}
          bg="white"
          {...props}
        />
        <InputLeftElement
          display="flex"
          align="center"
          justifyContent="center"
          h="100%"
          w={searchIconWidth}
        >
          <Icon as={BsSearch} color="mandala.black.500" />
        </InputLeftElement>

        <InputRightElement display="flex" align="center" h="100%">
          {isLoading ? (
            <Button
              variant="transparent"
              isLoading={true}
              color="mandala.black.500"
              onClick={() => {}}
              rounded="full"
              _hover={{ opacity: 1 }}
            />
          ) : (
            isClearable &&
            displayValue && (
              <IconButton
                icon={<MdCancel />}
                variant="transparent"
                onClick={handleClearSearch}
                color="mandala.black.500"
                rounded="full"
                size="sm"
                _hover={{ color: "mandala.red.500" }}
              />
            )
          )}
        </InputRightElement>
      </InputGroup>
    );
  }
);

SearchInput.displayName = "SearchInput";

export default SearchInput;
