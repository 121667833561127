import React from "react";
import { Input, Button, Text, Flex } from "@chakra-ui/react";
import InputWrapper from "../../Fragments/InputWrapper";
import { OverflownText } from "../../Text/OverflownText";

const FileInput = ({
  name,
  label,
  isInvalid,
  errorMessage,
  onChange = () => {},
  isButtonUploadMinimized = false,
  value,
  isDisabled,
  id,
  wrapperProps = {},
  withOverflownText = false,
  ...props
}) => {
  const inputFileRef = React.useRef();

  const [isDragActive, setIsDragActive] = React.useState(false);

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setIsDragActive(true);
      return;
    }
    setIsDragActive(false);
  };

  const handleOnChange = file => {
    onChange(file);
    // reset value so user can select even the same file
    inputFileRef.current.value = null;
  };

  const onDropHandler = e => {
    // prevents default behavior so the dropped file won't be viewed/downloaded
    e.preventDefault();
    setIsDragActive(false);
    const dataTransferItems = e.dataTransfer.items;
    if (!dataTransferItems) {
      return;
    }

    const listOfFiles = [...dataTransferItems].map(item => {
      // if current item is not a file, reject them
      if (item.kind === "file") {
        // dropped file will only be accessible via "DataTransferItemList" interface
        // spesificly with getAsFile.
        return item.getAsFile();
      }
    });
    // for current development (API compability), only return
    // the first valid file
    handleOnChange(listOfFiles[0]);
  };

  const onClickHandler = () => {
    if (isDisabled) {
      return;
    }
    inputFileRef.current.click();
  };

  const FileNameRenderer = withOverflownText ? OverflownText : Text;

  return (
    <InputWrapper
      id={id}
      label={label}
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      onClick={onClickHandler}
      onDrop={onDropHandler}
      onDragOver={handleDrag}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      borderColor={isDragActive ? "mandala.blue.500" : "transparent"}
      borderWidth="thin"
      borderStyle="solid"
      borderRadius="base"
      transitionDuration="150ms"
      _hover={{
        cursor: "pointer"
      }}
      {...wrapperProps}
    >
      {isButtonUploadMinimized ? (
        <Text
          color="mandala.blue.600"
          fontWeight="bold"
          float="right"
          marginBottom="-20px"
          position="relative"
        >
          Upload Content
        </Text>
      ) : (
        <Flex alignItems="center" mb={isInvalid ? 3 : 0}>
          <Button
            isDisabled={isDisabled}
            data-testid="choose-file-button"
            variant="outline"
            borderColor="mandala.black.300"
            fontWeight={500}
            flexShrink={0}
          >
            Choose File
          </Button>
          <FileNameRenderer px={4}>
            {value ? value?.get(name).name : "No file choosen"}
          </FileNameRenderer>
        </Flex>
      )}
      <Input
        name={name}
        ref={inputFileRef}
        onChange={event => handleOnChange(event.currentTarget.files[0])}
        display="none"
        type="file"
        {...props}
      />
    </InputWrapper>
  );
};

export default FileInput;
