import React from "react";
import { Input } from "@chakra-ui/react";
import InputWrapper from "../../Fragments/InputWrapper";

const DateInput = ({ label, placeholder, wrapperProps = {}, ...props }) => {
  const { isInvalid = false, errorMessage = "Value is invalid" } = props;

  return (
    <InputWrapper
      label={label}
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      {...wrapperProps}
    >
      <Input type="date" w="90%" placeholder={placeholder} {...props} />
    </InputWrapper>
  );
};

export default DateInput;
