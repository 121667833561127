import React from "react";
import { Box, Flex, Text, Checkbox, Icon } from "@chakra-ui/react";
import { RiErrorWarningFill } from "react-icons/ri";

const CheckboxInput = ({
  label = "Checkbox",
  subtitle,

  onChange = () => {},
  isChecked = false,
  isIndeterminate = false,

  errorMessage = "Something is wrong",
  wrapperProps = {},
  ...props
}) => {
  const { isInvalid } = props;
  const hasSubtitle = Boolean(subtitle);

  return (
    <Box w="100%" {...wrapperProps}>
      <Flex w="100%" align="center">
        <Checkbox
          onChange={onChange}
          isChecked={isChecked}
          isIndeterminate={isIndeterminate}
          {...props}
        />

        <Text px=".5em">{label}</Text>
      </Flex>
      {hasSubtitle && (
        <Text variant="subtitle" mt=".5em" color="mandala.black.500">
          {subtitle}
        </Text>
      )}
      {isInvalid && (
        <Flex align="center" color="mandala.red.500" mt=".5em">
          <Icon as={RiErrorWarningFill} />
          <Text variant="subtitle" color="mandala.red.500" px=".5em">
            {errorMessage}
          </Text>
        </Flex>
      )}
    </Box>
  );
};

export default CheckboxInput;
