import React from "react";

import { Modal } from "../../../Modal";
import {
  Button,
  Flex,
  ModalHeader,
  ModalFooter,
  ModalBody
} from "@chakra-ui/react";

import CodeEditor from "./CodeEditor";

const CodeEditorModal = ({
  isOpen,
  onClose,
  onFinish,
  value: initialValue,
  isCodeEditorDisabled = false,
  modalTitle = "Code Editor",
  mode,
  modalProps = {
    width: "80%",
    height: "65vh"
  }
}) => {
  const [value, setValue] = React.useState(initialValue);

  // Return inner state value to the parents and close modal
  const handleOnFinish = () => {
    onFinish(value);
    onClose();
  };

  // Autopopulate value when opening modal
  React.useEffect(() => {
    if (!isOpen) {
      return;
    }

    setValue(initialValue);
  }, [initialValue, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      width={modalProps.width}
      data-testid="code-editor-modal"
    >
      <ModalHeader>{modalTitle}</ModalHeader>
      <ModalBody>
        <Flex
          w="100%"
          height={modalProps.height}
          border="solid 1px"
          borderColor="mandala.black.300"
          borderRadius=".25em"
          id="code-editor-modal"
        >
          <CodeEditor
            readOnly={isCodeEditorDisabled}
            value={value}
            onChange={setValue}
            name="code-editor-modal"
            style={{
              borderRadius: ".25em"
            }}
            mode={mode}
          />
        </Flex>
      </ModalBody>

      <ModalFooter>
        <Button
          variant="outline"
          mr={3}
          onClick={onClose}
          data-testid="code-editor-cancel-button"
        >
          Cancel
        </Button>
        <Button
          onClick={handleOnFinish}
          id="code-editor-modal-done-button"
          data-testid="code-editor-done-button"
        >
          Done
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CodeEditorModal;
