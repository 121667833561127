import React from "react";

// Hooks for Generating Table Width & Height
export const useRectMeasure = (deps = []) => {
  const [rect, setRect] = React.useState({});
  const divRef = React.useRef();
  const resizeTable = React.useCallback(() => {
    if (divRef.current) {
      const divRect = divRef.current.getBoundingClientRect();
      setRect(divRect);
    }
  }, []);

  // On browser paint the DOM, get wrapper width and height
  React.useLayoutEffect(() => {
    resizeTable();

    window.addEventListener("resize", resizeTable);
    return () => window.removeEventListener("resize", resizeTable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);

  // Get Percentage of Each Column
  const getPercentage = arrayOfPercentage => {
    // Default value 0, when rect.width is undefined
    if (!rect.width) {
      return arrayOfPercentage.map(() => 0);
    }

    const maxWidth = rect.width;

    return arrayOfPercentage.map(v => maxWidth * (v / 100));
  };

  return {
    rect,
    ref: divRef,
    getPercentage
  };
};
