import React from "react";
import dynamic from "next/dynamic";

// Load worker for react-ace
// https://github.com/securingsincity/react-ace/issues/725
const AceEditor = dynamic(
  async () => {
    const reactAce = await import("react-ace");

    // prevent warning in console about misspelled props name.
    await import("ace-builds/src-min-noconflict/ext-language_tools");

    // import theme & mode here
    await import("ace-builds/src-min-noconflict/mode-sql");
    await import("ace-builds/src-min-noconflict/mode-python");

    let ace = require("ace-builds/src-min-noconflict/ace");

    ace.config.set(
      "basePath",
      "https://cdn.jsdelivr.net/npm/ace-builds@1.4.8/src-noconflict/"
    );

    ace.config.setModuleUrl(
      "ace/mode/javascript_worker",
      "https://cdn.jsdelivr.net/npm/ace-builds@1.4.8/src-noconflict/worker-javascript.js"
    );

    return reactAce;
  },
  // react-ace doesn't support server side rendering as it uses the window object.
  {
    ssr: false
  }
);

const _CodeEditor = (props, ref) => (
  <AceEditor
    ref={ref}
    width="100%"
    height="100%"
    fontSize={14}
    theme="textmate"
    wrapEnabled={true}
    focus={true}
    setOptions={{
      useWorker: false,
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
      enableSnippets: false,
      showLineNumbers: true,
      tabSize: 2,
      readOnly: props.isDisabled
    }}
    {...props}
  />
);

const CodeEditor = React.forwardRef(_CodeEditor);

export default CodeEditor;
