import Wrapper from "../../Fragments/InputWrapper";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import { DROPDOWN_ACTION_TYPES } from "src/constants/dropdown";
import { Text } from "@chakra-ui/react";
import { defaultStyle } from "src/theme/components/select";

const generateBaseComponent = isOptionCreatable => {
  if (isOptionCreatable) {
    return CreatableSelect;
  }

  return ReactSelect;
};

const SelectDropdown = ({
  label = "Select value",
  hasLabel = true,
  placeholder = "Select option(s)",
  value = null,
  options = [],
  subtitle,
  wrapperProps = {},
  onChange = () => {},
  onClear = () => {},

  isLoading,
  isRequired = false,
  isOptionCreatable = false,
  isSearchable = isOptionCreatable ?? false,
  isClearable = true,
  isDisabled = false,
  isMultipleValue = false,
  useOptionRawValue = false,

  isDuplicateOptionValueAllowed = true, // Note: This is an advance properties, use it when you have to.
  uniqueValue = [{ value: null }], // Supply hardcoded value into options dropdown
  ...props
}) => {
  const SelectDropdown = generateBaseComponent(isOptionCreatable);
  const hasSubtitle = Boolean(subtitle);

  const handleOnInteract = (propsValue, propsAction) => {
    const { action } = propsAction;

    switch (action) {
      // Handle clear dropdown value
      // This is achieved by clicking the "x" button (applicable when isClearable is true)
      case DROPDOWN_ACTION_TYPES.CLEAR:
        onClear();
        return;

      case DROPDOWN_ACTION_TYPES["REMOVE-VALUE"]:
        onChange(!propsValue ? [] : propsValue);
        return;

      case DROPDOWN_ACTION_TYPES["POP-VALUE"]:
        onChange(!propsValue ? [] : propsValue);
        return;

      // Handle create custom value
      case DROPDOWN_ACTION_TYPES["CREATE-OPTION"]: {
        const { value = "" } = propsValue;
        if (isMultipleValue || useOptionRawValue) {
          onChange(propsValue);
          return;
        }

        onChange(value);
        return;
      }

      // Handle select value
      case DROPDOWN_ACTION_TYPES["SELECT-OPTION"]: {
        const { value = "" } = propsValue;
        // return one row values of the selected option
        if (isMultipleValue || useOptionRawValue) {
          onChange(propsValue);
          return;
        }

        onChange(value);
        return;
      }
    }
  };

  // stringValue to get string of value {Object/String}
  const stringValue = value?.value ?? value;

  // Function that detects whether selectables dropdown support create custom options
  const _isUsingCreateableOption = () => {
    if (!isOptionCreatable || !stringValue) {
      return false;
    }

    const isValueIsIncludedInOptions = options.findIndex(
      ({ value: v }) => v === stringValue
    );

    return isValueIsIncludedInOptions === -1;
  };

  const isUsingCreateableOption = _isUsingCreateableOption();

  // Generate custom options for dropdown selections
  const optionsData =
    isUsingCreateableOption && options.length
      ? [...options, { value: stringValue, label: stringValue }]
      : options;

  return (
    <Wrapper
      label={label}
      hasLabel={hasLabel}
      subtitle={subtitle}
      isInvalid={isRequired}
      data-testid={props["data-testid"]}
      {...wrapperProps}
    >
      <SelectDropdown
        onChange={handleOnInteract}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isRequired={isRequired}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isMulti={isMultipleValue}
        options={options}
        placeholder={placeholder}
        value={
          isDuplicateOptionValueAllowed
            ? optionsData.find(({ value: v }) => v === stringValue) ||
              value ||
              null
            : uniqueValue
        }
        styles={{
          ...defaultStyle,
          menu: provided => ({ ...provided, zIndex: 10 })
        }}
        {...props}
      />
      {hasSubtitle && (
        <Text variant="subtitle" mt=".5em" color="mandala.black.500">
          {subtitle}
        </Text>
      )}
    </Wrapper>
  );
};

export default SelectDropdown;
