import React from "react";
import { Text } from "@chakra-ui/react";
import { SelectDropdown } from "src/components/MandalaUI/Selectables";
import { REACT_SELECT_ACTION_TYPE } from "src/constants/dropdown";

const EMAIL_REGEX = new RegExp(/^[\w._+-]+@[a-zA-Z_]+?\.[a-zA-Z]{2,4}$/, "i");

// Overide "react-select" Menu component
const Menu = props => {
  const { options } = props;

  const isTypingNewEmail =
    options[0] && options[0]["__isNew__"] && options[0].value.length;

  const isValidEmail = EMAIL_REGEX.test(options[0]?.value);

  // Give dropdown subtitles when it count as a valid email
  if (!isTypingNewEmail || !isValidEmail) {
    return null;
  }

  return (
    <Text variant="subtitle" color="mandala.black.500" px=".5em" mt=".5em">
      Press &quot;Enter&quot; to add &quot;{options[0].value}&quot;
    </Text>
  );
};

const EmailInput = ({ emails, setEmails }) => {
  const [isValidEmail, setIsValidEmail] = React.useState(true);

  // Handler for selecting dropdown/selectable
  const handleOnChange = props => {
    // Handle delete from 1 to 0, without this the "props" value is registered as a "null"
    // Therefore breaking the code
    if (!props) {
      setEmails([]);
    }

    // Handle Email Validation
    // Find the latest added email value
    const hasRecentlyAddValue = props?.find(
      ({ __isNew__: isRecentlyAdded }) => isRecentlyAdded
    );

    if (hasRecentlyAddValue) {
      // Validate with the regex
      const _isValidEmail = EMAIL_REGEX.test(hasRecentlyAddValue.value);

      // If the email is not valid, toggle flag. So the UI display information about the error
      if (!_isValidEmail) {
        setIsValidEmail(false);
        return;
      }
    }

    // Clear "__isNew__" identifier to separate between the newly added and the existing list of emails.
    // And assign it to react state
    const copyValue = props?.map(({ value, label }) => ({ value, label }));
    setEmails(copyValue);
    // Reset toggle flag
    setIsValidEmail(true);
  };

  // Handle on typing in the selectables/dropdown component
  // This function is used for reseting toggle flag when user type something
  const handleOnInputChange = (_value, { action }) => {
    if (action !== REACT_SELECT_ACTION_TYPE["INPUT-CHANGE"] || isValidEmail) {
      return;
    }

    setIsValidEmail(true);
  };

  return (
    <SelectDropdown
      onChange={handleOnChange}
      onInputChange={handleOnInputChange}
      label="Enter email address"
      placeholder="Email Address"
      isRequired={!isValidEmail}
      isMultipleValue={true}
      isOptionCreatable={true}
      value={emails}
      wrapperProps={{
        ...(!isValidEmail && { errorMessage: "Please provide a valid email" })
      }}
      options={[]}
      components={{
        IndicatorsContainer: () => null,
        Menu
      }}
    />
  );
};

export default EmailInput;
