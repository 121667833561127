import { colors } from "src/theme/foundations/colors";

export const defaultStyle = {
  container: base => {
    return {
      ...base,
      flex: 1
    };
  },
  control: (provided, state) => {
    const { isDisabled } = state;
    const { isRequired } = state.selectProps;

    return {
      ...provided,
      backgroundColor: isDisabled
        ? colors.mandala.black[200]
        : colors.mandala.black[50],
      padding: "1px 2px",
      borderRadius: "3px",
      borderColor: isRequired
        ? colors.mandala.red[500]
        : colors.mandala.black[300],
      ":hover": {
        cursor: isDisabled ? "not-allowed" : "default",
        outline: "none",
        boxShadow: `0 0 0 0px ${
          isRequired ? colors.mandala.red[500] : colors.mandala.blue[500]
        }`,
        borderColor: isRequired
          ? colors.mandala.red[500]
          : colors.mandala.blue[500]
      }
    };
  }
};
